export const AuthRoutes = {
    login: "/login"
};

export const AdminRoutes = {
    home: "/",
    revenue: "/revenue/:id",
    revenueMenu: "/revenue",
    accounts: "/accounts/:id",
    accountsMenu: "/accounts",
    payroll: "/payroll",
    settings: "/settings"
};