import React from 'react'
import { MenuType } from '../../types/global.t';
import useAppMisc from '../../utils/hooks/useAppMisc';
import Button from '../controls/button';
import Link from '../controls/link';
import { IconAngleLeft, IconAngleRight } from '../icons';

export interface CrumbItem {
    name: string;
    url?: string;
    onClick?: () => void;
}

export interface BreadcrumbProps {
    crumbs?: Array<CrumbItem>;
    showBackbutton?: boolean;
}

interface CrumbProps {
    item: CrumbItem,
    hasSibling?: boolean;
    onClick?: ()=> void;
}

export const toBreadCrumb = (item: CrumbItem)=> {
    return item
}

function Breadcrumb({ crumbs, showBackbutton }: BreadcrumbProps) {
    
    const appMisc = useAppMisc();

    if(appMisc.currentNavigation) crumbs = crumbs || appMisc.currentNavigation;
    
    if (crumbs?.length === 0) return null;

    return (
        <div className='breadcrump'>
            {showBackbutton ? <Button fancy={true} icon={<IconAngleLeft />} /> : null}
            <div style={{marginLeft: showBackbutton ? "var(--space-lg)" : undefined}} className='crumbs'>
                {
                    crumbs?.map((item, i) => <Crumb key={item.name + i} onClick={()=> appMisc.setMenu({show: true, type: MenuType.ACCOUNT})} item={item} hasSibling={(i + 1) !== crumbs?.length} />)
                }
            </div>
        </div>
    )
}

const Crumb = ({ item, hasSibling, onClick }: CrumbProps) => {
    return (
        <div style={{ opacity: hasSibling ? 0.6 : 1 }} className='crumb'>
            {
                item?.url ?
                    <Link text={item.name} url={item.url} /> :
                    <Button onClick={onClick } text={item.name} />
            }
            {hasSibling ? <div className='bcb-icon'><IconAngleRight /></div> : null}
        </div>
    )
}

export default Breadcrumb