import { useState, Fragment } from 'react'
import { AdminRoutes } from '../../utils/exports/RoutePaths'
import Button from '../controls/button'
import Link, { LinkProps } from '../controls/link/Link'
import { IconAccounts, IconAngleLeft, IconAngleRight, IconApp, IconFeedback, IconHelp, IconHome, IconIntegration, IconRevenue, IconSetting } from '../icons'
import ListItem from '../list'

const r = AdminRoutes

function SideBar() {
  const [isClosed, setIsClosed] = useState(false);
  return (
    <Fragment>
      <div className={`sidebar${isClosed ? " sd-closed" : " sd-opened"}`}>
        <div className='sd-toggle'>
          <Button onClick={() => setIsClosed(!isClosed)} icon={isClosed ? <IconAngleRight /> : <IconAngleLeft />} />
        </div>
        <div className='sd-content'>
          <div className='side-navs'>
            <L icon={<IconHome />} exact={true} url={r.home} text='Home' />
            <L title='In-Apps' icon={<IconApp />} url={r.revenueMenu} text='In-Apps' />
            <L title='Transactions' icon={<IconRevenue />} url={r.revenueMenu} text='Transactions' />
            <L title='Customers' icon={<IconAccounts />} url={r.accountsMenu} text='Customers' />
            <L title='Integration' icon={<IconIntegration />} url={r.payroll} text='Payouts' />
          </div>
          <div className='side-bottom'>
            <div className='side-navs'>
              <L title='Settings' icon={<IconSetting />} url={r.settings} text='Settings' />
            </div>
          </div>
          <div className='side-bottom'>
            <div className='side-navs'>
              <L icon={<IconFeedback />} url='/feedback' text='Feedback' />
              <L icon={<IconHelp />} url='/support' text='Support' />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const L = ({ text, icon, url, ...props }: LinkProps) => {
  return (
    <Link className='link' activeClassName='nav-active' url={url} exact={false} {...props}>
      <ListItem
        className='link'
        title={text}
        left={icon}
      />
    </Link>
  )
}

export default SideBar