import React from 'react'

function SecondarySideBar() {
  return (
    <div className='sec-sidebar'>
        <div>jh</div>
    </div>
  )
}

export default SecondarySideBar