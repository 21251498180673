import React from 'react'
import { ChartGraphicAnimation } from '../../components/animation/Animations'
import { Content } from '../../components/skeleton'

function HomePage() {
    return (
        <Content
            headerProps={{
                title: "Welcome, Visitor",
            }}
        >
            <div className='home'>
                <div className='center' style={{ flexDirection: "column", textAlign: "center" }}>
                    <div style={{ width: 280 }} className='gg'>
                        <ChartGraphicAnimation />
                    </div>
                    <div className='gt'>Charts will be displayed here once they're loaded.</div>
                </div>
            </div>
        </Content>
    )
}

export default HomePage