/* eslint-disable react-hooks/exhaustive-deps */
import Dashboard from './pages/dashboard';
import { BrowserRouter as Router } from 'react-router-dom'

function App() {

  return (
    <Router>
      <Dashboard />
    </Router>

  );
}

export default App;
