import React from 'react'
import LogoImage from '../../assets/svgs/brand/logo.svg';


interface Props {
    size?: number | string;
    className?: string;
    showLogoText?: boolean;
}

function Logo({ className, size = undefined, showLogoText = false }: Props) {
    return (
        <>
            <div style={{ height: size }} className={`scflogo ${className}`}>
                <div className='logo-image-wrapper'><img src={LogoImage} alt='' /></div>
                <>{showLogoText && (<span style={{ paddingLeft: '7px' }}></span>)}</>
                {showLogoText && <div className='logo-text'>ChuttApp</div>}
            </div>
        </>
    )
}

export default Logo