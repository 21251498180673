import { FiSun, FiUsers, FiSearch, FiHelpCircle, FiChevronDown, FiPlus } from 'react-icons/fi'
import { BiUser } from 'react-icons/bi'
import { FaRegHandshake } from 'react-icons/fa'
import { IoSettingsOutline, IoTelescopeOutline, IoDocumentTextOutline, IoClose,  } from 'react-icons/io5'
import { IoMdMoon, IoIosArrowForward, IoIosArrowBack, IoIosRefresh } from 'react-icons/io'
import { BsChatSquareDots , BsLink45Deg, BsArrowRight, BsArrowLeft, BsLock} from 'react-icons/bs'
import { GoHome } from 'react-icons/go'
import { IoExtensionPuzzleOutline } from 'react-icons/io5'
import { MdQueryStats , MdOutlineAlternateEmail} from 'react-icons/md'
import { AiOutlineLaptop } from 'react-icons/ai'
import { HiOutlineSquares2X2 } from 'react-icons/hi2'

interface Props {
    color?: string;
    size?: string | number
}

const s = 18;
const c = "var(--color-light)"



export const IconHome = ({ color = c, size = s }) => <GoHome color={color} size={size} />
export const IconRevenue = ({ color = c, size = s }) => <MdQueryStats color={color} size={size} />
export const IconAccounts = ({ color = c, size = s }) => <FiUsers color={color} size={size} />
export const IconInventory = ({ color = c, size = s }) => <AiOutlineLaptop color={color} size={size} />
export const IconPolicy = ({ color = c, size = s }) => <FaRegHandshake color={color} size={size} />
export const IconIntegration = ({ color = c, size = s }) => <IoExtensionPuzzleOutline color={color} size={size} />

export const IconOverview = ({ color = c, size = s }) => <IoTelescopeOutline color={color} size={size} />
export const IconReports = ({ color = c, size = s }) => <IoDocumentTextOutline color={color} size={size} />

export const IconAngleDown = ({ color = c, size = s }) => <FiChevronDown color={color} size={size} />

export const IconUser2 = ({ color = c, size = s }: Props) => <BiUser color={color} size={size} />;

export const IconSearch = ({ color = c, size = s }: Props) => <FiSearch color={color} size={size} />;

export const IconDarkTheme = ({ color = c, size = s }) => <IoMdMoon color={color} size={size} />
export const IconLightTheme = ({ color = c, size = s }) => <FiSun color={color} size={size} />
export const IconSetting = ({ color = c, size = s }) => <IoSettingsOutline color={color} size={size} />


export const IconHelp = ({ color = c, size = s }) => <FiHelpCircle color={color} size={size} />
export const IconFeedback = ({ color = c, size = s }) => <BsChatSquareDots color={color} size={size} />


export const IconAngleLeft = ({ color = c, size = s }) => <IoIosArrowBack color={color} size={size} />
export const IconAngleRight = ({ color = c, size = s }) => <IoIosArrowForward color={color} size={size} />


export const IconLink = ({ color = c, size = s }) => <BsLink45Deg color={color} size={size} />

export const IconClose = ({ color = c, size = s }) => <IoClose color={color} size={size} />

export const IconRefresh = ({ color = c, size = s }) => <IoIosRefresh color={color} size={size} />
export const IconNext = ({ color = c, size = s }) => <BsArrowRight color={color} size={size} />
export const IconPrevious = ({ color = c, size = s }) => <BsArrowLeft color={color} size={size} />


export const IconPlus = ({ color = c, size = s }) => <FiPlus color={color} size={size} />

export const IconEmail = ({ color = c, size = s }) => <MdOutlineAlternateEmail color={color} size={size} />
export const IconPassword = ({ color = c, size = s }) => <BsLock color={color} size={size} />;

export const IconApp = ({ color = c, size = s }) => <HiOutlineSquares2X2 color={color} size={size} />
