export const appendClass = (...classNames: Array<string | undefined>) => {
  return classNames?.join(" ");
};

export const isEmpty = (data: any) => {
  return typeof data === "undefined" || data == null || data === "";
};

export const stringify = (data: any) => {
  if (isEmpty(data)) return "";
  return JSON.stringify(data);
};

export const parseJSON = (data: any) => {
  if (isEmpty(data)) return null;
  return JSON.parse(data);
};

export const getStoreItem = (key: string) => {
  const val = parseJSON(localStorage.getItem(key));
  if (isEmpty(val)) return null;
  return val;
};

export const saveStoreItem = (key: string, data: any) => {
  if (isEmpty(key) || isEmpty(data)) return false;
  localStorage.setItem(key, stringify(data));
  return true;
};

export const onEnterKeyPress = (e: any) => {
  if (e?.key === "Enter") {
    return (callback?: () => void) => {
      callback?.();
    };
  }
};
