import { useDispatch, useSelector } from "react-redux";
import { saveStoreItem } from "..";
import { setLoginState } from "../local-store/AppStore";
import { RootState } from "../local-store/store";

function useAuthState() {
  const { isLoggedIn } = useSelector((state: RootState) => state.appStore);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(setLoginState(false));
    window.location.reload()
    localStorage.removeItem("token")
  };
  const login = () => {
    dispatch(setLoginState(true));
    saveStoreItem("token", "some_token_here");
    window.location.reload()
  };

  return {
    isLoggedIn,
    logout,
    login,
  };
}

export default useAuthState;
