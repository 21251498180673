import React from 'react'
import { NavItem } from '../../types/global.t';
import Button from '../controls/button';
import Link from '../controls/link';



interface Props {
    navs: Array<NavItem>;
    route?: string
}

function SubNav({ navs, route }: Props) {
    return (
        <div className='sub-nav'>
            {
                navs.map((a, i) => {
                    const { name, url, onClick } = a
                    return (
                        url ?
                            <Link exact={true} activeClassName='s-nav-active' className='nav-btn-item' url={route + url} text={name} />
                            :
                            <Button className='nav-btn-item' onClick={onClick} text={name} />
                    )
                })
            }
        </div>
    )
}

export default SubNav