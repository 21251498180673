import React from 'react'
import { appendClass } from '../../../utils';


interface ModalProps {
    children?: any;
    blur?: boolean;
    className?: string;
    transparent?: boolean;
    centerContent?: boolean;
}

function Modal({ children, className, transparent = true, blur, centerContent = true }: ModalProps) {
    if (blur) className = "blur-bg";
    if (transparent) className = "transparent-bg";
    if(centerContent) className += " center"

    return (
        <div className={appendClass('modal', className)}>
            {children}
        </div>
    )
}

export default Modal