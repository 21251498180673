import React from 'react'
import { Link as L, useResolvedPath, useMatch } from 'react-router-dom'
import { appendClass } from '../../../utils'
import Click, { ClickableProps } from '../button/Click'


export interface LinkProps extends ClickableProps {
    url: string;
    activeClassName?: string;
    exact?: boolean;
    children?: any;
}

export const Link: React.FC<LinkProps> = ({ url, children, title, className, exact = true, activeClassName, ...props }) => {
    let resolved = useResolvedPath(url);
    let match = useMatch({ path: resolved.pathname, end: exact });

    if (!match) {
        activeClassName = undefined
    }
    console.log(title, "hjtghgkjh", title ? `${title}` : undefined)
    return (
        <L title={title} className={appendClass('link', activeClassName)} to={url}>
            {
                children ?? 
                <Click className={appendClass("link", className)} {...props} />
            }
        </L>
    )
}


export default Link