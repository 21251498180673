import React from 'react'
import Button from '../../components/controls/button'
import { SubNav } from '../../components/header'
import { Content } from '../../components/skeleton'
import useAuthState from '../../utils/hooks/useAuthState'

function SettingsPage() {
    const navs = [
        {
            name: "General",
            url: "/",
        },
        {
            name: "API Keys & Webhooks",
            url: "/user-mang",
        },
        {
            name: "Preferences",
            url: "/user-mang",
        },
    ]

    const {logout} = useAuthState()

    return (
        <Content
            headerProps={{
                title: "Settings",
            }}
        >

            <SubNav navs={navs} route="/settings" />

            <div>
                <Button text='Logout' type='link' onClick={()=> {
                    logout();
                    window.location.reload()
                }} />
            </div>

        </Content>
    )
}

export default SettingsPage