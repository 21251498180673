import Lottie from "lottie-react";

interface Props {
    file?: any;
    loop?: boolean;
}
const Animator = ({file: animationFile, loop}: Props) => {
  return <Lottie loop={loop} animationData={animationFile} />;
};

export default Animator;