import React from 'react'
import { appendClass } from '../../utils';
import Breadcrumb, { BreadcrumbProps } from './Breadcrumb'


export interface TitleProps {
  style?: React.CSSProperties
}
export interface SecondaryHeaderProps {
  title?: string | React.ReactNode;
  right?: React.ReactNode;
  breadcrumbProps?: BreadcrumbProps
  titleProps?: TitleProps;
  titleCentered?: boolean;
  showNavHistory?: boolean;
  className?: string;
}



function SecondaryHeader({ title, right, breadcrumbProps, className, titleProps , titleCentered, showNavHistory}: SecondaryHeaderProps) {

  if (typeof title === "string") title = <div className={appendClass('hd-title', titleCentered ? "hd-tile-center": undefined)} style={titleProps?.style}>{title}</div>;


  return (
    <div className={appendClass('sec-header', className)}>
      {showNavHistory ? <Breadcrumb {...breadcrumbProps} /> : null}
      <div  className='sec-hd-bucket'>
        <div className='sec-hd-left'>{title}</div>
        <div className='sec-hd-right'>{right}</div>
      </div>
    </div>
  )
}

export default SecondaryHeader