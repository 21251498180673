import React from 'react'
import useAppMisc from '../../../utils/hooks/useAppMisc';
import Button from '../../controls/button';
import { SearchInput, SearchProps } from '../../controls/input'
import { Breadcrumb, SecondaryHeader } from '../../header';
import { IconClose } from '../../icons';

interface Props {
    children?: any;
    data?: Array<any>;
    render?: ({ item, index }: { item: any, index: number }) => React.ReactNode;
    showNavHistory?: boolean;
    searchOptions?: SearchProps,
    title?: string;
    header?: React.ReactNode
}

function GrandMenu({ children, data, showNavHistory, render, header, searchOptions, title }: Props) {
    const appMisc = useAppMisc()
    return (
        <div className='gr-menu'>
            {
                showNavHistory ?
                    <SecondaryHeader
                        className="mod-head"
                        title={title}
                        right={
                            <div className='flex'>
                                <SearchInput {...searchOptions} autoFocus={true} />
                                <Button onClick={()=> appMisc.setMenu(undefined)} className='' fancy={true} icon={<IconClose />} />
                            </div>
                        }
                    />
                    :
                    <div className='gr-head'>
                        <SearchInput {...searchOptions} />
                    </div>
            }
            {showNavHistory ? <div className='center gr-nv'>
                <Breadcrumb />
            </div> : null}
            <div className='gr-mn-grid'>
                {header}
                {
                    data?.length ?
                        data.map((item, i) => render?.({ item, index: i }))
                        :
                        children
                }
            </div>
        </div>
    )
}

export default GrandMenu