import React from 'react'
import Button from '../../components/controls/button'
import { SubNav } from '../../components/header'
import { IconNext, IconPrevious, IconRefresh } from '../../components/icons'
import { Content } from '../../components/skeleton'
import Modal from '../../components/views/modal'
import { MenuType } from '../../types/global.t'
import useAppMisc from '../../utils/hooks/useAppMisc'
import AccountsMenu from './AccountsMenu'

function AccountsPage() {

  const navs = [
    {
      name: "Sheet",
      url: "/current"
    },
    {
      name: "Financial Metric",
      url: "/9s8d789s7d-sd87s90fdd8dsd"
    },
    {
      name: "Notes",
      url: "/9s8d789s7d-sd87s90fdd8dscd?note=true"
    },
  ]

  return (
    <React.Fragment>
      <Content
        headerProps={{
          title: "Account name",
          showNavHistory: true,
          right: (
            <div className='flex'>
              <div className='flex'>
                <Button title="Previous" className='sb-nav-btn' fancy={true} icon={<IconPrevious />} />
                <Button title="Next" className='sb-nav-btn' fancy={true} icon={<IconNext />} />
                <Button title="Refresh" className='sb-nav-btn' fancy={true} icon={<IconRefresh />} />
              </div>
            </div>
          )
        }}
      >
        <SubNav navs={navs} route="/accounts" />
      </Content>
      <Menu />
    </React.Fragment>
  )
}

const Menu = () => {
  const appMisc = useAppMisc();
  return (
    appMisc?.interactiveMenu?.show && appMisc?.interactiveMenu?.type === MenuType.ACCOUNT ?
      <Modal>
        <div className='gr-menu-mod-content'>
          <AccountsMenu showNavHistory={true} />
        </div>
      </Modal>
      : null
  )
}

export default AccountsPage