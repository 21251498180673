import { useState } from 'react'
import { GrandMenu, MenuItem } from '../../components/views/menu';

function AccountsMenu({ showNavHistory }: { showNavHistory?: boolean }) {

    const [data,] = useState<Array<any>>([
        {
            title: "Finance",
            subtitle: "Financial Statements for property 13",
            children: [
                {
                    title: "About Money",
                    subtitle: "Test subtitles",
                    children: [
                        {
                            title: "About Money",
                            id: "90890-djdhfsd-f-93sd-fd",
                        },
                        {
                            title: "About Money",
                            children: [
                                {
                                    title: "About Money",
                                    id: "90890-djdhfsd-f-93sd-fd",
                                },
                                {
                                    title: "About Money",
                                    id: "90890-djdhfsd-f-93sd-fd",
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Effective Rental Income",
            subtitle: "Financial Statements for property 13",
            children: [
                {
                    title: "About Money",
                    subtitle: "Test subtitles",
                    id: "90890-djdhfsd-f-93sd-fd",
                }
            ]
        },
        {
            title: "Commercial Income",
            subtitle: "Financial Statements for property 13",
            children: [
                {
                    title: "About Money",
                    id: "90890-djdhfsd-f-93sd-fd",
                }
            ]
        },
        {
            title: "Finance",
            subtitle: "Financial Statements for property 13",
            children: [
                {
                    title: "About Money",
                    subtitle: "Test subtitles",
                    id: "90890-djdhfsd-f-93sd-fd",
                }
            ]
        },
        {
            title: "Effective Rental Income",
            subtitle: "Financial Statements for property 13",
            children: [
                {
                    title: "About Money",
                    subtitle: "Test subtitles",
                    id: "90890-djdhfsd-f-93sd-fd",
                }
            ]
        },
        {
            title: "Commercial Income",
            subtitle: "Financial Statements for property 13",
            children: [
                {
                    title: "About Money",
                    id: "90890-djdhfsd-f-93sd-fd",
                },
                {
                    title: "About Money",
                    id: "90890-djdhfsd-f-93sd-fd",
                }
            ]
        },
    ])

    return (
        <GrandMenu
        title='Accounts'
            showNavHistory={showNavHistory}
            data={data}
            render={({ item, index }) => (
                <MenuItem
                    key={item?.id + index.toString()}
                    item={item}
                />
            )
            }
            searchOptions={{
                placeholder: "Search accounts"
            }}
        >
        </GrandMenu>
    )
}

export default AccountsMenu;