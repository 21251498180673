import { useDispatch, useSelector } from "react-redux";
import { getStoreItem } from "..";
import { themeKey } from "../exports/Keys";
import { setTheme, Theme } from "../local-store/AppStore";
import { RootState } from "../local-store/store";

function useTheme() {
  const dispatch = useDispatch();
  const { theme } = useSelector((state: RootState) => state.appStore);

  const toggleTheme = ()=> {
    dispatch(setTheme(getStoreItem(themeKey) as Theme))
  }

  return {
      toggleTheme,
      isDark: theme === Theme.DARK
  }
}

export default useTheme;
