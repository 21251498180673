import { useDispatch, useSelector } from "react-redux";
import { NavItem } from "../../types/global.t";
import {
  setCurrentNavigation,
  InteractiveMenu,
  setShowInteractiveMenu,
} from "../local-store/AppMiscStore";
import { RootState } from "../local-store/store";

function useAppMisc() {
  const dispatch = useDispatch();
  const { currentNavigation, interactiveMenu } = useSelector(
    (state: RootState) => state.appMiscStore
  );

  const setNavigation = (nav: Array<NavItem>) => {
    dispatch(setCurrentNavigation(nav));
  };

  const setMenu = (menu: InteractiveMenu | undefined) => {
    dispatch(setShowInteractiveMenu(menu!));
  };

  return {
    setNavigation,
    setMenu,
    currentNavigation,
    interactiveMenu,
  };
}

export default useAppMisc;
