import { configureStore } from '@reduxjs/toolkit'
import AppMiscStore from './AppMiscStore'
import AppStore from './AppStore'

export const store = configureStore({
  reducer: {
      appStore: AppStore,
      appMiscStore: AppMiscStore
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch