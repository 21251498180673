import React from 'react'
import { SecondaryHeader, SecondaryHeaderProps } from '../header';

interface Props {
    children?: any;
    headerProps?: SecondaryHeaderProps
}

function Content({ children, headerProps }: Props) {
    return (
        <div className='scf-content'>
            <SecondaryHeader {...headerProps} />
            {children}
        </div>
    )
}

export default Content