/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Skeleton } from '../../components/skeleton';
import { AdminRoutes, AuthRoutes } from '../../utils/exports/RoutePaths';
import useAuthState from '../../utils/hooks/useAuthState';
import AccountsPage from '../accounts';
import SignInPage from '../auth';
import HomePage from '../home';
import SettingsPage from '../user-account/SettingsPage';

const r = { ...AdminRoutes, ...AuthRoutes }

function Dashboard() {
    const { isLoggedIn } = useAuthState()
    const nav = useNavigate()

    useEffect(() => {
        if (!isLoggedIn) nav("/login")
        else nav("/")
        return () => {
        }
    }, [])

    return (
        isLoggedIn ?
            <Skeleton>
                <Routes>
                    <Route path={r.home} element={<HomePage />} />
                    <Route path={r.home} element={<HomePage />} />
                    <Route path={r.accounts} element={<AccountsPage />} />
                    <Route path={r.settings} element={<SettingsPage />} />
                </Routes>
            </Skeleton>
            :
            <Routes>
                <Route path={r.login} element={<SignInPage />} />
            </Routes>
    )
}

export default Dashboard