import React from 'react'
import { LoginGraphicAnimation } from '../../components/animation/Animations'
import { Logo } from '../../components/brand'
import Button from '../../components/controls/button'
import Input from '../../components/controls/input'
import { IconEmail, IconPassword, } from '../../components/icons'
import useAuthState from '../../utils/hooks/useAuthState'

function SignInPage() {
    const auth = useAuthState();

    return (
        <div className='auth-container'>
            <div className='auth-content'>
                <div className='au-left'>
                    <div className='au-ms-head'>
                        <Logo showLogoText={true} size={20} />
                    </div>
                    <div className='au-head'>
                        <div style={{ textAlign: "left", textTransform: "uppercase" }} className='au-note'>
                            Welcome Back
                        </div>
                        <div className='au-tile'>Sign In</div>
                    </div>
                    <div className='au-form'>
                        <div className='au-field'>
                            <Input left={<IconEmail />} placeholder='Email' />
                        </div>
                        <div className='au-field'>
                            <Input left={<IconPassword />} type='password' placeholder='Password' />
                        </div>
                        <div><Button className='au-sm-b' text='Forgot password?' type='link' /></div>
                        <div className='au-f-btn'>
                            <Button onClick={()=> auth.login()} className='au-prime' text='Sign in' type='solid' />
                        </div>
                        <div className='au-iso'>
                            <div className='au-note'>
                                Don't have an account?
                            </div>
                            <Button className='au-sec' text='Sign up' type='tint' />
                        </div>
                    </div>
                </div>
                <div className='au-right'>
                    <div>
                        <LoginGraphicAnimation />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInPage