import React from 'react'
import { IconSearch } from '../../icons'
import Input, { InputProps } from './Input'

export interface SearchProps extends InputProps { }

function SearchInput({ placeholder = "Search", ...rest }: SearchProps) {
    return (
        <Input
            {...rest}
            placeholder={placeholder}
            right={<IconSearch />}
        />
    )
}

export default SearchInput