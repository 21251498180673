import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuType, NavItem } from "../../types/global.t";


export interface InteractiveMenu {
    show?: boolean,
    type?: MenuType
}

// Define a type for the slice state
interface AppMiscStoreState {
  currentNavigation?: Array<NavItem>;
  interactiveMenu?: InteractiveMenu;
}

const initialState: AppMiscStoreState = {};

export const AppMiscStore = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setCurrentNavigation: (state, action: PayloadAction<Array<NavItem>>) => {
      state.currentNavigation = action.payload;
    },
    setShowInteractiveMenu: (state, action: PayloadAction<InteractiveMenu>) => {
      state.interactiveMenu = action.payload;
    },
  },
});

export const { setCurrentNavigation , setShowInteractiveMenu} = AppMiscStore.actions;

export default AppMiscStore.reducer;
