import React from 'react'
import useTheme from '../../utils/hooks/useTheme'
import Avatar from '../avatar'
import { Logo } from '../brand'
import Button from '../controls/button'
import { SearchInput } from '../controls/input'
import { IconDarkTheme, IconLightTheme } from '../icons'

function Header() {
    const { isDark, toggleTheme } = useTheme()

    return (
        <div className='header'>
            <div className='hd-bucket'>
                <div className='hd-left'>
                    <Logo showLogoText={true} size='24px' />
                </div>
                <div className='center hd-right'>
                    <div className='center hd-links'>
                        <Button type='tint' text='Test Mode' />
                    </div>
                    <div className='center hd-r-left'>
                        <SearchInput placeholder='Search' />
                    </div>
                    <div className='center hd-r-right'>
                        <Button onClick={toggleTheme} className='hd-btn' icon={isDark ? <IconLightTheme /> : <IconDarkTheme />} />
                        <Avatar />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header