import React from 'react'
import Header from '../header';
import SideBar from '../sidebar';

interface Props {
    children?: any;
}

function Skeleton({ children }: Props) {
    return (
        <React.Fragment>
            <div className='scfskeleton'>
                <Header />
                <div className='scfwrapper'>
                    <SideBar />
                    <div className='scfcontainer'>
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Skeleton