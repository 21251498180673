import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { NavItem } from '../../../types/global.t';
import useAppMisc from '../../../utils/hooks/useAppMisc';
import { IconAngleDown, IconAngleRight, IconLink } from '../../icons'
import ListItem, { ListItemProps } from '../../list'

interface IMenuItem {
    title: string;
    subtitle?: string;
    children?: IMenuItem[];
    id?: string;
}


interface MenuItemProps extends ListItemProps {
    isChild?: boolean;
    item: IMenuItem;
    expanded?: boolean;
    navHistory?: Array<NavItem>;
}

function MenuItem({ item, isChild, navHistory, expanded, ...rest }: MenuItemProps) {

    const { title, children, subtitle, id } = item;

    const appnav = useAppMisc();
    const nav = useNavigate();
    const [isExpanded, setIsExpanded] = useState(expanded);

    let background = isExpanded ? "var(--brand-color)" : "var(--blue)";
    let color = isChild ? "var(--color)" : 'white'

    if (isChild) background = "transparent";

    const goToAccount = () => {
        console.log(navHistory)
        if (!id) return;
        nav(id);
        appnav.setNavigation(navHistory ?? []);
    }

    return (
        id ?
            <ListItem
                onClick={() => goToAccount()}
                className='menu-item menu-link'
                title={title}
                containerStyle={{ color: "var(--blue) !important" }}
                left={<div style={{ background: "transparent", opacity: 0.3 }} className='badge'><IconLink size={15} /></div>}
            />

            :
            <div className='menu-item-content'>
                <ListItem
                    onClick={() => setIsExpanded(!isExpanded)}
                    className='menu-item'
                    left={
                        children?.length! > 0 ?
                            <div style={{ background }} className='badge'>
                                {
                                    isExpanded ? <IconAngleDown color={color} /> : <IconAngleRight color={color} />
                                }
                            </div>
                            : <div style={{ background }} className='badge'></div>
                    }
                    title={title}
                    subtitle={subtitle}
                />
                <div className='mn-lks'>
                    {
                        isExpanded ?
                            children?.map((a, i) => (
                                <MenuItem
                                    key={item?.id + i.toString()}
                                    item={a}
                                    isChild={true}
                                    navHistory={navHistory ? [...navHistory, { name: item.title }] : [{ name: item.title }]}
                                />
                            ))
                            : null
                    }
                </div>
            </div>
    )
}

export default MenuItem